import { FC, memo } from 'react'

import { Carousel } from '@headless/ui'
import { useCatalogRelated } from '@hooks/CatalogPage'

import ProductItem from '@components/ProductItem'
import { StyledItem, StyledRelated } from './styled'

interface CatalogRelatedProps {
  url: string
}

const CatalogRelated: FC<CatalogRelatedProps> = ({ url }) => {
  const { loading, products } = useCatalogRelated(url)

  return (
    <StyledRelated>
      <h3 className="title">
        <span>Shop Similar</span>
      </h3>
      {loading ? (
        <p />
      ) : (
        <>
          {products.length > 0 ? (
            <Carousel
              dots={false}
              nextArrowIcon={() => <i className="icon icon-next" />}
              prevArrowIcon={() => <i className="icon icon-prev" />}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false
                  }
                }
              ]}
            >
              {products.map((product: any) => {
                return (
                  <StyledItem key={product.id}>
                    <ProductItem product={product} showHover={false} />
                  </StyledItem>
                )
              })}
            </Carousel>
          ) : (
            <p>There is no any product.</p>
          )}
        </>
      )}
    </StyledRelated>
  )
}

export default memo(CatalogRelated)
