import { DocumentNode, gql } from '@apollo/client'

export const GET_CATEGORY_FILTER_SEO: DocumentNode = gql`
  query GetCategoryFilterSeo($id: String!, $layer_filter_items: [String]) {
    categories(
      filters: { category_uid: { in: [$id] } }
      layer_filter_items: $layer_filter_items
    ) {
      items {
        id
        seo_rich_snippets
        uid
        name
        meta_title
        meta_keywords
        meta_description
        url_key
      }
    }
  }
`
