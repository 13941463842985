import styled from '@emotion/styled'

export const StyledShared = styled.ul`
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  align-items: center;
  justify-content: center;

  > li {
    margin-right: 1.25rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .icon {
    display: block;
    background: url('/images/social.svg') no-repeat;
    width: 1.5rem;
    height: 1.625rem;
    display: block;
    background-size: 2000%;

    &-pinterest {
      background-position: -219px 2px;
    }

    &-email {
      width: 1.75rem;
      background-position: -449px 1px;
      background-size: 1700%;
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`
