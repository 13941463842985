import { Helmet } from 'react-helmet'

import { Container, Link } from '@headless/ui'

import { StyledNotFound } from './styled'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <Container>
        <StyledNotFound>
          <picture>
            <img src="/images/404.svg" alt="not found page" />
          </picture>
          <h3>Oh no! Page not found.</h3>
          <Link href="/" title="Take me to homepage">
            Take me to homepage
          </Link>
        </StyledNotFound>
      </Container>
    </>
  )
}

export default NotFound
