import styled from '@emotion/styled'

export const StyledRelated = styled.div`
  margin-top: 6rem;

  .title {
    color: #222;
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid #e5e5e5;
    word-spacing: 0.1em;
  }

  .slick-prev {
    top: 50%;
    left: 0 !important;
    z-index: 1;
    transform: translateY(-50%);
  }

  .slick-next {
    top: 50%;
    right: 22px !important;
    z-index: 1;
    transform: translateY(-50%);
  }

  .icon {
    display: block;
    height: 50px;
    width: 50px;
    opacity: 0.6;
    background: #fff url('/images/nav.png') no-repeat;
    background-position: center;
    background-size: 20%;
    transition: all 0.3s;

    &-prev {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 2rem;

    .slick-dots {
      bottom: -2.5rem;

      > li {
        button {
          &:before {
            border: 1px solid #f2e0d6;
          }
        }

        &.slick-active {
          button {
            &:before {
              border-color: #f2e0d6;
            }
          }
        }
      }
    }
  }
`

export const StyledItem = styled.div`
  padding-right: 1.375rem;

  .product {
    &__name {
      margin-top: 0.9375rem;

      > a {
        color: #222;
        font-weight: 400;
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
      }
    }

    &__price {
      color: #222;
      font-size: 1.125rem;
      font-weight: 500;
      text-align: center;
    }

    &__reivews {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    padding-right: 0.5rem;
  }
`
