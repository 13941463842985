/* eslint-disable */
import CategoryLayout from '@components/CategoryLayout'
import CategoryDetail from '@components/CategoryPage/CategoryDetail'
import { useStaticCategoryPage } from '@hooks/StaticCategoryPage/useStaticCategoryPage'
import { FC, memo, useEffect, useState } from 'react'

export interface StaticCategoryPageProps {
  id: number
  asPath: string
  uid: string
}

const StaticCategoryPage: FC<StaticCategoryPageProps> = (
  props: StaticCategoryPageProps
) => {
  const {
    id,
    filters,
    filterInputs,
    sortBy,
    page,
    aggregations,
    categories,
    sortFields,
    categoryFilterSeo
  } = useStaticCategoryPage(props)

  const [hydrated, setHydrated] = useState(false)

  useEffect(() => {
    // This forces a rerender, so the date is rendered
    // the second time but not the first
    setHydrated(true)
  }, [])

  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null
  }

  return (
    <>
      <CategoryDetail id={id} categoryFilterSeo={categoryFilterSeo} />
      <CategoryLayout
        id={id}
        filterInputs={filterInputs}
        filters={filters}
        sortBy={sortBy}
        page={page}
        aggregations={aggregations}
        categories={categories}
        sortFields={sortFields}
      />
    </>
  )
}

export default memo(StaticCategoryPage)
