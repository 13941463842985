import { NextPage, NextPageContext } from 'next/types'

import { RouteConf } from '@config/route'
import { GET_ALL_CATEGORIES } from '@graphql/queries/getAllCategories'

import CatalogPage from '@components/CatalogPage'
import CmsPage from '@components/CmsPage'
import NotFound from '@components/NotFound'
import StaticCategoryPage from '@components/StaticCategoryPage'
import { GET_URL_RESOLVER } from '@graphql/queries/getUrlResolver'

interface ResolverProps {
  id: number
  search: string
  type: string
  urlKey: string
  category?: { id: number; uid: string; name: string; url_key: string }
  asPath?: string
  uid?: string
}

interface ApolloPageContext extends NextPageContext {
  apollo: any
}

const Resolver: NextPage<any> = ({
  id,
  search,
  type,
  urlKey,
  asPath,
  uid,
  ...props
}: ResolverProps) => {
  switch (type) {
    case RouteConf.CMS_PAGE:
      return <CmsPage id={id} {...props} />
    case RouteConf.CATEGORY:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return <StaticCategoryPage id={id} uid={uid} asPath={asPath!} />
    case RouteConf.STATIC_CATEGORY:
      return (
        <StaticCategoryPage
          id={id}
          uid={uid}
          asPath={globalThis?.location?.pathname}
        />
      )
    case RouteConf.PRODUCT:
      return <CatalogPage urlKey={urlKey} />
    default:
      return <NotFound />
  }
}

Resolver.getInitialProps = async ({
  apollo,
  asPath,
  res
}: ApolloPageContext) => {
  const match: string[] = (asPath || '').split('/').slice(1)
  const pathname: string = match.join('/')
  const url: any = match?.pop()
  const urlKey: string = url.split('.')?.shift() || ''
  const search: string = url.split('?')?.pop() || ''

  // 判断是否是产品目录页
  const paths = asPath?.split('/')
  const category = asPath?.split('/')[1]

  let retryResult: any

  const { data: categoryData } = await apollo.query({
    query: GET_ALL_CATEGORIES,
    variables: {},
    fetchPolicy: 'cache-first'
  })

  const { allCategories } = categoryData

  const { items } = allCategories

  if (category && paths) {
    const find = items.find((item: any) => item.url_key === category)

    if (find) {
      return {
        type: RouteConf.STATIC_CATEGORY,
        search,
        id: find.id,
        asPath,
        uid: find.uid
      }
    }
  }

  const { data } = await apollo.query({
    query: GET_URL_RESOLVER,
    variables: {
      url: pathname
    },
    fetchPolicy: 'no-cache'
  })

  const resolver = data?.urlResolver ?? retryResult?.data?.urlResolver ?? {}

  if (resolver.redirectCode === 301) {
    return {
      urlKey: resolver.relative_url.split('.')?.shift() || '',
      ...resolver
    }
  }

  if (!data?.urlResolver && !retryResult?.data?.urlResolver) {
    if (res) res.statusCode = 404
    return { type: '404', pathname }
  }

  return {
    search,
    urlKey,
    ...resolver
  }
}

export default Resolver
