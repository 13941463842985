import { gql } from '@apollo/client'

export const GET_BREADCRUMBS = gql`
  query GetBreadcrumbs($category_id: String!) {
    categories(filters: { category_uid: { in: [$category_id] } }) {
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        breadcrumbs {
          category_uid
          # We may not need level if \`breadcrumbs\` is sorted.
          category_level
          category_name
          category_url_path
        }
        uid
        name
        url_path
      }
    }
  }
`
