import { gql, DocumentNode } from '@apollo/client'

export const ADD_DISCOUNT_INFO: DocumentNode = gql`
  mutation addDiscountInfo($input: DiscountInfoInput) {
    addDiscountInfo(input: $input) {
      code
      content
      message
    }
  }
`
