import { useMutation } from '@apollo/client'
import { clsx } from 'clsx'
import { FC, memo, useEffect, useState } from 'react'

import { CREATE_REVIEW_HELPFUL } from '@graphql/mutations/createReviewHelpful'

interface ReviewHelpProps {
  review: any
}

const ReviewHelp: FC<ReviewHelpProps> = ({ review }) => {
  const [createHelpfulMutation] = useMutation(CREATE_REVIEW_HELPFUL)
  const [upCount, setUpCount] = useState(0)
  const [upActive, setUpActive] = useState(false)
  const [downCount, setDownCount] = useState(0)
  const [downActive, setDownActive] = useState(false)
  const { review_helpful } = review

  const handleHelpfulUp = async () => {
    const helpItems = review_helpful?.items ?? []
    const match = helpItems.find((item: any) => item.is_owner === 1)

    try {
      await createHelpfulMutation({
        variables: {
          input: {
            id: match ? match.id : 0,
            is_help: 1,
            is_not_help: 0,
            review_id: review.id
          }
        }
      })
    } catch (error) {
      return null
    }
  }

  const handleHelpfulDown = async () => {
    try {
      const helpItems = review_helpful?.items ?? []
      const match = helpItems.find((item: any) => item.is_owner === 1)

      await createHelpfulMutation({
        variables: {
          input: {
            id: match ? match.id : 0,
            is_help: 0,
            is_not_help: 1,
            review_id: review.id
          }
        }
      })
    } catch (error) {
      return null
    }
  }

  const handleHelpUpClick = () => {
    if (upActive) {
      setUpCount((prevState) => prevState - 1)
      setUpActive(false)
      handleHelpfulDown()
    } else {
      setUpCount((prevState) => prevState + 1)
      if (downActive && downCount > 0) {
        setDownCount((prevState) => prevState - 1)
        setDownActive(false)
      }
      setUpActive(true)
      handleHelpfulUp()
    }
  }

  const handleHelpDownClick = () => {
    if (downActive) {
      setDownCount((prevState) => prevState - 1)
      setUpActive(false)
      setDownActive(false)
      handleHelpfulUp()
    } else {
      setDownCount((prevState) => prevState + 1)
      if (upActive && upCount > 0) {
        setUpCount((prevState) => prevState - 1)
        setUpActive(false)
      }
      setDownActive(true)
      handleHelpfulDown()
    }
  }

  useEffect(() => {
    const helpItems = review_helpful?.items ?? []
    const match = helpItems.find((item: any) => item.is_owner === 1)

    setUpCount(review_helpful.is_help_count)
    setUpActive(match?.is_help === 1)
    setDownCount(review_helpful.is_not_help_count)
    setDownActive(match?.is_not_help === 1)
  }, [review_helpful])

  return (
    <div className="reviews__help">
      <p
        className={clsx({
          active: upActive
        })}
        aria-hidden="true"
        onClick={handleHelpUpClick}
      >
        <i className="icon icon-helpful" />
        <span>{upCount}</span>
      </p>
      <p
        className={clsx({
          active: downActive
        })}
        aria-hidden="true"
        onClick={handleHelpDownClick}
      >
        <i className="icon icon-uness" />
        <span>{downCount}</span>
      </p>
    </div>
  )
}

export default memo(ReviewHelp)
