/* eslint-disable @typescript-eslint/no-shadow */
import { useLazyQuery, useQuery } from '@apollo/client'

import { StaticCategoryPageProps } from '@components/StaticCategoryPage/staticCategoryPage'
import { GET_ALL_CATEGORIES } from '@graphql/queries/getAllCategories'
import { GET_CATEGORY_FILTER_SEO } from '@graphql/queries/getCategoryFilterSeo'
import { GET_FILTER_INPUTS } from '@graphql/queries/getFilterInputs'
import { GET_PRODUCT_FILTERS } from '@graphql/queries/getProductFilters'
import { GET_SORT_FIELDS } from '@graphql/queries/getSortFields'
import { useEffect, useState } from 'react'

export const useStaticCategoryPage = (props: StaticCategoryPageProps) => {
  const { id, asPath, uid } = props

  const [sortBy, setSortBy] = useState({})

  const [page, setPage] = useState(1)

  const [filters, setFilters] = useState<Array<any>>([])

  const [aggregations, setAggregations] = useState<Array<any>>([])

  const [categories, setCategories] = useState<Array<any>>([])

  const [sortFields, setSortFields] = useState<Array<any>>([])

  const [reqCategoryFilterSeo, { data: categoryFilterSeoRes }] = useLazyQuery(
    GET_CATEGORY_FILTER_SEO
  )
  const { data: productFilterData } = useQuery(GET_PRODUCT_FILTERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      filters: {
        category_id: {
          eq: id
        }
      }
    }
  })

  const { data: sortFieldsData } = useQuery(GET_SORT_FIELDS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      filters: {}
    }
  })

  const { data: filterInputsData } = useQuery(GET_FILTER_INPUTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const { data: categoriesData } = useQuery(GET_ALL_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (asPath) {
      const staticPaths = asPath.split('/')

      const filtersPaths = staticPaths.slice(2)

      const sortFieldOptions = sortFieldsData?.productSort?.sort_fields.options

      if (sortFieldOptions?.length) {
        if (filtersPaths) {
          const index = filtersPaths.findIndex(
            (item: string) => item === 'product_list_order'
          )
          if (index > -1) {
            const find = sortFieldOptions.find(
              (ele: any) => ele.value === filtersPaths[index + 1]
            )
            if (find) {
              setSortBy(find)
            }
          }
        }
      }
    }
  }, [asPath, sortFieldsData?.productSort?.sort_fields.options])

  useEffect(() => {
    if (asPath) {
      const staticPaths = asPath.split('/')

      const filtersPaths = staticPaths.slice(2)

      const tempArry: Array<any> = []
      const layerFilterItems: string[] = []

      if (productFilterData) {
        const { productFilters } = productFilterData

        setAggregations(productFilters.aggregations)

        if (filtersPaths.length > 0) {
          filtersPaths.forEach((temp) => {
            productFilters.aggregations.forEach((aggregation: any) => {
              if (aggregation.label === 'Category') return
              const { options } = aggregation
              const find = options.find((item: any) => item.alis_value === temp)
              if (find) {
                tempArry.push({
                  aggregation,
                  option: find
                })
                layerFilterItems.push(find.label)
              }
            })
          })
        }
      }

      reqCategoryFilterSeo({
        variables: {
          id: uid,
          layer_filter_items: layerFilterItems
        }
      })

      setFilters(tempArry)
    }
  }, [asPath, productFilterData, uid, reqCategoryFilterSeo])

  useEffect(() => {
    if (asPath) {
      let temp = 1
      const staticPaths = asPath.split('/')

      const filtersPaths = staticPaths.slice(2)

      if (filtersPaths.length > 0) {
        const index = filtersPaths.findIndex((item: string) => item === 'p')
        if (index > -1 && parseInt(filtersPaths[index + 1], 10)) {
          temp = parseInt(filtersPaths[index + 1], 10)
        }
      }

      temp = temp && temp > 0 ? temp : 1

      setPage(temp)
    }
  }, [asPath])

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        if (categoriesData) {
          const { allCategories } = categoriesData
          const { aggregations, items, sort_fields } = allCategories
          setCategories(items)
          setAggregations(aggregations)
          setSortFields(sort_fields)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchAllCategories()
  }, [categoriesData])

  return {
    id,
    filters,
    sortBy,
    filterInputs: filterInputsData?.__type?.inputFields ?? [],
    aggregations,
    page,
    categories,
    sortFields,
    categoryFilterSeo: categoryFilterSeoRes?.categories?.items?.[0] || null
  }
}
