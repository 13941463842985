import { gql } from '@apollo/client'

export const GET_STORE_CONFIG_DATA = gql`
  query GetStoreConfigForBreadcrumbs {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    storeConfig {
      store_code
      category_url_suffix
    }
  }
`
