import { isEmpty } from 'lodash'
import Head from 'next/head'
import { FC, memo } from 'react'

import { Link, Portal } from '@headless/ui'
import { useCategoryDetail } from '@hooks/CategoryPage'

import AppBreadcrumbs from '@components/AppBreadcrumbs'
import useScript from '@hooks/useScript'
import { truncateDescription } from 'workspaces/utils/tools/truncateDescription'

interface CategoryDetailProps {
  id: number
  categoryFilterSeo?: any
}

const CategoryDetail: FC<CategoryDetailProps> = ({ id, categoryFilterSeo }) => {
  const { category } = useCategoryDetail(id)
  const seoRichSnippets = categoryFilterSeo?.seo_rich_snippets || []

  useScript(seoRichSnippets, { type: 'application/ld+json' })

  const breadcrumbs = category?.breadcrumbs ?? []

  return isEmpty(categoryFilterSeo) ? null : (
    <>
      <Head>
        {categoryFilterSeo.meta_title && (
          <meta name="title" content={categoryFilterSeo.meta_title} />
        )}
        {categoryFilterSeo.meta_keywords && (
          <meta name="keywords" content={categoryFilterSeo.meta_keywords} />
        )}
        {categoryFilterSeo.meta_description && (
          <meta
            name="description"
            content={truncateDescription(categoryFilterSeo.meta_description)}
          />
        )}
        {categoryFilterSeo.meta_title && (
          <title>{categoryFilterSeo.meta_title}</title>
        )}
      </Head>
      <AppBreadcrumbs>
        {breadcrumbs.map((breadcrumb: any) => {
          const { category_id, category_url_key, category_name } = breadcrumb
          return (
            <Link
              key={category_id}
              href={category_url_key}
              title={category_name}
              underline="hover"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: category_name
                }}
              />
            </Link>
          )
        })}
        <span dangerouslySetInnerHTML={{ __html: categoryFilterSeo?.name }} />
      </AppBreadcrumbs>
      {typeof window !== 'undefined' && (
        <Portal container={document.querySelector('.category__name')}>
          <span dangerouslySetInnerHTML={{ __html: categoryFilterSeo?.name }} />
        </Portal>
      )}
    </>
  )
}

export default memo(CategoryDetail)
