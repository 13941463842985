import styled from '@emotion/styled'

import { Rating } from '@headless/ui'

export const StyledReviews = styled.div`
  margin-top: 1.5625rem;

  .reviews {
    &__sort {
      display: flex;
      margin: 2rem 0;
      padding: 1.25rem 0;
      border-bottom: 1px solid #e5e5e5;
      border-top: 1px solid #e5e5e5;
    }

    &__title {
      margin-bottom: 1.125rem;
      color: #212529;
      font-size: 1.125rem;
      font-weight: 300;
      text-transform: capitalize;
    }

    &__content {
      font-size: 0.875rem;
    }

    &__image {
      display: grid;
      margin-top: 1.875rem;
      grid-template-columns: repeat(auto-fill, 5.625rem);
      grid-gap: 0.9375rem;

      img {
        width: 5.625rem;
        height: 7.5rem;
        display: block;
        object-fit: cover;
      }
    }

    &__help {
      margin-top: 2rem;
      padding-right: 1.25rem;
      color: #999;
      font-size: 0.875rem;
      text-align: right;

      > p {
        display: inline-block;
        margin-left: 1.5625rem;
        margin-bottom: 0;
        color: #999;
        font-size: 1rem;
        cursor: pointer;
      }

      .icon {
        background-image: url('/images/helpful.svg');
        width: 1rem;
        height: 1rem;
        display: inline-block;
        margin-right: 0.625rem;
        vertical-align: sub;
        background-repeat: no-repeat;
        background-size: contain;

        &-uness {
          vertical-align: middle;
          transform: rotate(180deg);
        }
      }

      .active {
        .icon {
          background-image: url('/images/helpful_active.svg');

          &-uness {
            vertical-align: middle;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .MuiInputBase-root {
    margin-right: 3.125rem;
    border-radius: 0;
  }

  .MuiSelect-select {
    width: 7rem;
    padding: 0.53125rem 1rem;
    font-size: 1rem;
    border-radius: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: #999 !important;
  }

  @media screen and (max-width: 767px) {
    .reviews {
      &__sort {
        justify-content: space-between;

        .MuiFormControlLabel-root {
          margin-right: 0;
        }
      }

      &__title {
        margin: 0.3125rem 0 0.3125rem;
        font-size: 0.875rem;
      }

      &__help {
        margin-top: 0.5rem;
        padding-right: 0;
      }

      &__content {
        font-size: 0.75rem;
      }
    }
  }
`

export const StyledRating = styled(Rating)({
  '&': {
    fontSize: '1.2rem'
  },
  '& .MuiRating-iconFilled': {
    color: '#000'
  },
  '& .MuiRating-iconEmpty': {
    color: '#ccc'
  }
})

export const StyledRatingItem = styled(Rating)({
  '&': {
    fontSize: '1.7rem'
  },
  '& .MuiRating-iconFilled': {
    color: '#000'
  },
  '& .MuiRating-iconEmpty': {
    color: '#ccc'
  }
})

export const StyleReviewList = styled.ul`
  > li {
    display: flex;
    padding: 1.875rem 0;
    flex-wrap: wrap;
    border-bottom: 1px solid #c9c9c9;
  }

  .rating {
    flex: 0 0 25%;
  }

  .details {
    flex: 75%;
  }

  @media screen and (max-width: 767px) {
    > li {
      padding: 1rem 0;
    }

    .rating {
      flex: 0 0 100%;

      p {
        font-size: 0.875rem;
      }

      .date {
        font-size: 0.75rem;
      }
    }

    .details {
      flex: 100%;
    }
  }
`

export const StyledPagination = styled.div`
  display: flex;
  margin-top: 2.5rem;
  justify-content: flex-end;
`

export const StyledReviewForm = styled.div`
  margin-top: 15px;
  padding: 50px 79px;
  background-color: #f6f6f4;

  .reviews {
    &__rating {
      > li {
        margin: 1rem 0;
      }
    }

    &__head {
      font-size: 1.125rem;

      &::after {
        content: '*';
        color: #ff1744;
      }
    }

    &__actions {
      margin: 2rem 0;
    }
  }

  .errors {
    color: #ff1744;
    font-size: 0.875rem;
  }

  legend {
    margin-bottom: 20px;
    font-size: 1.25rem;
    font-family: 'serif';
  }

  .MuiInputLabel-root {
    height: 2rem;
  }

  .MuiInputBase-input,
  .MuiInputBase-root {
    background-color: #fff;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
  }

  .MuiButton-root {
    width: 18rem;
    height: 3.75rem;
    color: #333;
    font-size: 1.125rem;
    font-weight: 500;
    border-radius: 0;
    border: 1px solid #cdcdcd;
    background-color: #fff;

    &:hover {
      background-color: #f2e0d6;
      border-color: #f2e0d6;
    }
  }

  .MuiButton-containedSizeLarge {
    margin-right: 1.25rem;
    background-color: #f2e0d6;
    border-color: #f2e0d6;

    &:hover {
      background-color: #fff;
      border-color: #cdcdcd;
    }
  }

  .reviews {
    &__add {
      position: relative;
      width: 6.25rem;
      height: 6.25rem;
      display: block;
      cursor: pointer;
      background-color: #fff;
      border: 2px #e1e1da solid;

      &:before {
        content: '';
        height: 1.25rem;
        width: 2px;
        background-color: #e1e1da;
        position: absolute;
        right: calc(50% - 1px);
        top: calc(50% - 2px);
        margin-top: -8px;
      }

      &:after {
        content: '';
        width: 1.25rem;
        height: 2px;
        background-color: #e1e1da;
        position: absolute;
        right: calc(50% - 10px);
        top: 50%;
        margin-top: -1px;
      }
    }

    &__drag {
      display: grid;
      margin-top: 20px;
      margin-bottom: 50px;
      grid-auto-flow: column;
      justify-content: flex-start;
      align-items: flex-end;
      grid-column-gap: 1.25rem;
    }

    &__upload {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 1.25rem;

      > li {
        position: relative;
        display: block;
        background-color: #eee;

        .image {
          width: 7.5rem;
          height: 7.5rem;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    &__close {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background: #fff url('/images/close.png') no-repeat;
      background-size: 75%;
      background-position: 2px 2px;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0.9375rem;

    .reviews {
      &__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
      }
    }

    .MuiButton-root {
      width: 100%;
      font-size: 0.875rem;
    }

    .MuiButton-containedSizeLarge {
      margin-right: 0;
    }
  }
`

export const StyledReviewWrite = styled.div`
  margin: 2rem 0 1rem;

  .MuiButton-root {
    width: 18rem;
    height: 3.75rem;
    color: #333;
    font-size: 1.125rem;
    font-weight: 500;
    border-radius: 0;
    border: 1px solid #f2e0d6;
    background-color: #f2e0d6;

    &:hover {
      background-color: #fff;
      border-color: #cdcdcd;
    }
  }

  @media screen and (max-width: 767px) {
    .MuiButton-root {
      width: 100%;
      height: 3rem;
    }
  }
`

export const StyledReviewContainer = styled.div`
  .block__title {
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding-bottom: 10px;
    border-bottom: 1px #e5e5e5 solid;

    > strong {
      color: #222;
      font-size: 1.875rem;
      font-family: 'serif';
      font-weight: 500;
    }
  }

  .MuiRating-root {
    font-size: 1.8rem;
  }

  .reviews {
    &__total {
      display: flex;
      align-items: center;
    }

    &__grid {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 2.5rem;
    }
  }

  @media screen and (max-width: 767px) {
    .MuiRating-root {
      font-size: 1.15rem;
    }

    .reviews {
      &__grid {
        grid-auto-flow: row;
      }
    }
  }
`
