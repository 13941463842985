import styled from '@emotion/styled'

export const StyledCatalog = styled.div`
  .MuiBreadcrumbs-root {
    margin: 2.1875rem 0;
  }

  @media screen and (max-width: 767px) {
    .MuiBreadcrumbs-root {
      display: none;
    }

    .MuiContainer-root {
      padding: 0 0.9375rem;
    }
  }
`
