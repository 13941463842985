import { memo, useEffect, useMemo, useState } from 'react'

import { Container, Link } from '@headless/ui'
import { useBreadcrumbs } from '@hooks/Breadcrumbs'
import { StyledBreadcrumbs } from './styled'

const Breadcrumbs = ({ categoryId, currentProduct }: any) => {
  const { currentCategory, currentCategoryPath, normalizedData } =
    useBreadcrumbs({ categoryId })

  const [hydrated, setHydrated] = useState(false)

  useEffect(() => {
    // This forces a rerender, so the date is rendered
    // the second time but not the first
    setHydrated(true)
  }, [])

  // For all links generate a fragment like "/ Text"
  const links = useMemo(() => {
    return normalizedData.map(({ text, path }: any) => {
      return (
        <Link href={path} key={text}>
          {text}
        </Link>
      )
    })
  }, [normalizedData])

  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null
  }

  // If we have a "currentProduct" it means we're on a PDP so we want the last
  // category text to be a link. If we don't have a "currentProduct" we're on
  // a category page so it should be regular text.
  const currentCategoryLink = currentProduct ? (
    <Link href={currentCategoryPath}>{currentCategory}</Link>
  ) : (
    <span>{currentCategory}</span>
  )

  const currentProductNode = currentProduct ? (
    <span>{currentProduct}</span>
  ) : null

  return (
    <Container>
      <StyledBreadcrumbs>
        <Link href="/">Home</Link>
        {links}
        {currentCategoryLink}
        {currentProductNode}
      </StyledBreadcrumbs>
    </Container>
  )
}

export default memo(Breadcrumbs)
