import { useQuery } from '@apollo/client'
import { GET_BREADCRUMBS } from '@graphql/queries/getBreadcrumbsQuery'
import { GET_STORE_CONFIG_DATA } from '@graphql/queries/getStoreConfigQuery'
import { useMemo } from 'react'

// Just incase the data is unsorted, lets sort it.
const sortCrumbs = (
  a: { category_level: number },
  b: { category_level: number }
) => a.category_level > b.category_level

// Generates the path for the category.
const getPath = (path: string, suffix: string) => {
  if (path) {
    return `/${path}${suffix || ''}`
  }

  // If there is no path this is just a dead link.
  return '#'
}

export const useBreadcrumbs = (props: { categoryId: string }) => {
  const { categoryId } = props

  const { data, loading, error } = useQuery(GET_BREADCRUMBS, {
    variables: { category_id: categoryId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const { data: storeConfigData } = useQuery(GET_STORE_CONFIG_DATA, {
    fetchPolicy: 'cache-and-network'
  })

  const categoryUrlSuffix = useMemo(() => {
    if (storeConfigData) {
      return storeConfigData.storeConfig.category_url_suffix
    }
  }, [storeConfigData])

  // When we have breadcrumb data sort and normalize it for easy rendering.
  const normalizedData = useMemo(() => {
    if (!loading && data && data.categories.items.length) {
      const breadcrumbData = data.categories.items[0].breadcrumbs

      return (
        breadcrumbData &&
        breadcrumbData
          .map((category: any) => ({
            category_level: category.category_level,
            text: category.category_name,
            path: getPath(category.category_url_path, categoryUrlSuffix)
          }))
          .sort(sortCrumbs)
      )
    }
  }, [categoryUrlSuffix, data, loading])

  // const { setShimmerType } = useInternalLink('category')

  return {
    currentCategory:
      (data && data.categories.items.length && data.categories.items[0].name) ||
      '',
    currentCategoryPath:
      (data &&
        data.categories.items.length &&
        `${data.categories.items[0].url_path}${categoryUrlSuffix || ''}`) ||
      '#',
    isLoading: loading,
    hasError: !!error,
    normalizedData: normalizedData || []
    // handleClick: setShimmerType
  }
}
