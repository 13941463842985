import styled from '@emotion/styled'

import { Breadcrumbs } from '@headless/ui'

export const StyledBreadcrumbs = styled(Breadcrumbs)({
  '&': {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  '& .MuiBreadcrumbs-separator': {
    position: 'relative',
    margin: '0 0.25rem'
  },
  '& .MuiLink-root': {
    fontSize: '0.75rem',
    color: '#222',
    textTransform: 'uppercase'
  },
  '& .MuiBreadcrumbs-li > span': {
    fontSize: '0.75rem',
    color: '#222',
    textTransform: 'uppercase'
  }
})
