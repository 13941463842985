import styled from '@emotion/styled'

export const StyledCmsPage = styled.div`
  .actions-toolbar {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 30px;
    justify-content: flex-start;
  }

  .cms {
    &__container {
      max-width: 1200px;
      margin: 0 auto;
    }

    &__title {
      padding: 3.75rem 0 1.5625rem;
      color: #000;
      font-size: 2.25rem;
      font-family: 'serif';
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
    }

    &__content {
      table {
        td,
        th {
          vertical-align: inherit;
        }
      }

      form {
        position: relative;

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          display: none;
          z-index: 20;
          background-image: url('/images/loader.gif');
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff;
          opacity: 0.5;
        }

        &.loading {
          &:after {
            display: block;
          }
        }
      }
    }

    &__featured-collections {
      max-width: unset;

      .cms__title {
        display: none;
      }
    }

    &__wholesale-program {
      .radiotext:hover:after {
        top: 2px !important;
        left: 2px !important;
      }

      input[type='radio']:checked + label.radiotext:after,
      input[type='checkbox']:checked + label.radiotext:after {
        top: 2px !important;
        left: 2px !important;
      }
    }

    &__shop-by-tik-tok {
      .sharecontent {
        display: none;
      }

      .influencerproducts {
        &:last-of-type {
          display: none;
        }
      }
    }
  }

  .aboutus {
    .title_block {
      display: block;
    }
    img {
      width: 100%;
    }
  }

  .error {
    margin-top: 5px;
    font-size: 0.875rem;
    font-style: unset;
    color: #e02b27;
  }

  textarea {
    width: 100%;
    min-height: 150px;
    font-weight: 400;
    color: #222;
    font-size: 1rem;
    padding: 1rem 1.0625rem;
    vertical-align: baseline;
    resize: vertical;
    border-radius: 1px;
    border: 1px solid #e1e1da;
  }

  button {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    cursor: pointer;
  }

  ul {
    padding-left: 1.375rem;
    font-size: 0.875rem;
    color: #222;

    li {
      margin: 0.3125rem 0;
      list-style: outside;
    }
  }

  h1 {
    color: #222;
    font-weight: 400;
  }

  input {
    width: 100%;
    height: 2.5rem;
    padding: 0 1.0625rem;
    font-weight: 400;
    color: #222;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.42857143;
    vertical-align: baseline;
    background: #fff;
    background-clip: padding-box;
    border-radius: 1px;
    border: 1px solid #e1e1da;
    box-shadow: none;
    outline: 0;

    &.error {
      border-color: #d22c2c;
    }

    &:focus {
      border-color: #222;
    }
  }

  a {
    text-decoration: none;
  }

  .field {
    > .label {
      display: block;
      margin-bottom: 4px;
      color: #999;
      font-size: 0.875rem;
    }

    input,
    textarea {
      &.error {
        border-color: #e02b27 !important;
      }
    }

    .error {
      margin-top: 5px;
      font-size: 0.875rem;
      font-style: unset;
      color: #e02b27;
    }

    .file_box {
      position: relative;
      margin-bottom: 1.25rem;

      input.error {
        border-color: #e1e1da;
      }

      label.error {
        position: absolute;
        bottom: -1.5rem;
        margin-top: 0;
        z-index: 20;
      }
    }

    &.required {
      > .label {
        &:after {
          font-size: 1.125rem;
          color: #dbb19f;
          content: '*';
        }
      }
    }
  }

  table {
    width: 100%;
    margin: 0.45rem 0;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;

    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          padding: 8px 10px;
          vertical-align: bottom;
        }
      }
    }
  }

  .social-links {
    padding-left: 0;

    > li {
      margin-right: 45px;
      list-style-type: none;

      &:last-of-type {
        margin-right: 0;
      }

      > a {
        width: 1.0625rem;
        height: 1.125rem;
        display: block;
        background-image: url('/images/social.svg');
        background-repeat: no-repeat;
      }

      &:nth-of-type(1) {
        > a {
          background-position: 2px -1px;
        }
      }

      &:nth-of-type(2) {
        > a {
          background-position: -95px 1px;
        }
      }

      &:nth-of-type(3) {
        > a {
          background-position: -193px 0;
        }
      }

      &:nth-of-type(4) {
        > a {
          background-position: -294px 0;
        }
      }
    }
  }

  @media only screen and (min-width: 1470px) {
    .cms {
      &__shop-by-tik-tok {
        max-width: 1440px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .cms {
      &__container {
        max-width: unset;
        padding: 0 0.9375rem;
      }

      &__shop-by-tik-tok {
        max-width: unset;
      }

      &__title {
        padding: 1.875rem 0 0.9375rem;
        font-size: 1.25rem;
      }

      &__featured-collections {
        padding: 0;
      }

      &__shop-by-tik-tok {
        .sharecontent {
          display: block;
        }

        .backwall {
          display: none;
        }

        .influencerproducts {
          &:not(:last-of-type) {
            display: none;
          }

          &:last-of-type {
            display: block;
          }
        }
      }
    }

    .collections {
      article {
        aside {
          flex-direction: column-reverse;

          &:nth-of-type(2) {
            flex-direction: column;

            section {
              &:first-of-type {
                > img {
                  &:first-of-type {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .actions-toolbar {
      display: flex;
      flex-wrap: wrap;

      > .primary {
        flex-basis: 100%;
      }
    }
  }
`
