import styled from '@emotion/styled'

export const StyledCatalogMain = styled.div`
  display: grid;
  grid-template-columns: 1fr 31.25rem;
  grid-column-gap: 1rem;
  align-items: flex-start;

  .product {
    &__title {
      margin-bottom: 0.625rem;
      color: #222;
      font-size: 2.25rem;
      font-family: 'Constantia';
      font-weight: 500;
    }

    &__price {
      margin: 1.5rem 0;
      color: #212529;
      font-size: 1.625rem;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 767px) {
    display: block;

    .product {
      &__title {
        font-size: 1.875rem;
      }
    }

    .render__item {
      padding: 0;
    }
  }
`

export const StyledCatalogDetail = styled.div`
  display: grid;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  justify-content: flex-start;
  align-items: center;

  .product {
    &__sku {
      color: #222;
      font-size: 0.875rem;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
`
