import { isEmpty } from 'lodash'
import { FC, forwardRef, memo, useState } from 'react'
import ImageUploading from 'react-images-uploading'

import FormControlLabel from '@mui/material/FormControlLabel'

import {
  BaseInput,
  BaseSelect,
  Button,
  Checkbox,
  Collapse,
  Image,
  MenuItem,
  Pagination,
  Select
} from '@headless/ui'

import { useCatalogReviews } from '@hooks/CatalogPage'

import ImageLightbox from '@components/ImageLightbox'
import ReviewHelp from './ReviewHelp'
import {
  StyleReviewList,
  StyledPagination,
  StyledRating,
  StyledRatingItem,
  StyledReviewContainer,
  StyledReviewForm,
  StyledReviewWrite,
  StyledReviews
} from './styled'

interface CatalogReviewsProps {
  product: any
  ref: any
}

type Option = {
  label: string
  value: string
}

const CatalogReviews: FC<CatalogReviewsProps> = forwardRef<
  HTMLDivElement,
  CatalogReviewsProps
>(({ product }, ref) => {
  const { sku, rating_summary, show_attribute, configurable_options } = product

  // cp
  const cpColors: Array<Option> =
    show_attribute &&
    show_attribute
      .find((item: any) => item.code === 'super_color')
      ?.value.split(', ')
      .map((color: any) => ({ label: color, value: color }))

  // 子产品
  const colorsChildren: Option[] =
    configurable_options &&
    configurable_options[0]?.values?.map((item: any) => ({
      label: item.label,
      value: item.label
    }))

  // 简单产品的颜色
  const colorsSimple: Array<Option> =
    show_attribute &&
    show_attribute
      .find((item: any) => item.code === 'color')
      ?.value.split(', ')
      .map((color: any) => ({ label: color, value: color }))

  const colors = cpColors || colorsChildren || colorsSimple || []

  const defaultColor = colors.length === 1 ? colors[0]?.value : ''

  const {
    collapsed,
    control,
    errors,
    images,
    isDirty,
    reviews,
    reviewMeta,
    sort,
    currentPage,
    ratings,
    totalPage,
    thumbnailImages,
    formatDate,
    setCurrentPage,
    setRatings,
    handleSortChecked,
    handleCollapseClick,
    handleFormSubmit,
    handleSortChange,
    handleImageChange,
    setValue
  } = useCatalogReviews({ sku, defaultColor })

  const [index, setIndex] = useState<number>(0)
  const [isOpen, setisOpen] = useState<boolean>(false)

  const handleOpenLight = (url: string) => {
    const match: any = thumbnailImages.findIndex((image) => image === url)
    setIndex(match)
    setisOpen(true)
  }

  const onCloseRequest = () => {
    setisOpen(false)
  }
  const onMovePrevRequest = () => {
    setIndex((prev: any) => prev - 1)
  }
  const onMoveNextRequest = () => {
    setIndex((prev: any) => prev + 1)
  }

  return (
    <StyledReviewContainer>
      <div className="block__title">
        <strong>Reviews</strong>
      </div>
      <div className="block__content">
        <div className="reviews__total">
          <StyledRating
            value={(rating_summary * 5) / 100}
            precision={0.1}
            readOnly
          />
          <span className="count">{(rating_summary * 5) / 100}</span>
        </div>
        <div>
          <StyledReviewWrite>
            <Button variant="contained" onClick={handleCollapseClick}>
              Write A Review
            </Button>
          </StyledReviewWrite>
          <Collapse in={collapsed}>
            <StyledReviewForm>
              <legend>
                <span>What`s your thoughts about this item?</span>
              </legend>
              <form noValidate onSubmit={handleFormSubmit}>
                <div>
                  <ul className="reviews__rating">
                    {reviewMeta.map((meta: any) => {
                      const attr: string = `rating_${meta.id}`
                      const value: any = ratings[attr]
                      return (
                        <li key={meta.id}>
                          <p className="reviews__head">
                            <span>{`Your ${meta.name.toLowerCase()}`}</span>
                          </p>
                          {!isEmpty(ratings) && (
                            <StyledRatingItem
                              value={value}
                              onChange={(e: any, param: any) => {
                                setRatings({
                                  ...ratings,
                                  [attr]: param
                                })
                              }}
                            />
                          )}
                          {value === 0 && isDirty && (
                            <p className="errors">
                              Please select one of each of the ratings above.
                            </p>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="reviews__grid">
                  <BaseInput
                    name="nickname"
                    label="Nick Name"
                    required
                    control={control}
                    errors={errors}
                  />
                  <BaseInput
                    name="summary"
                    label="Summary"
                    required
                    control={control}
                    errors={errors}
                  />
                </div>
                <BaseInput
                  name="text"
                  label="Review"
                  fullWidth
                  required
                  control={control}
                  errors={errors}
                  multiline
                  rows={4}
                />
                <div className="reviews__grid">
                  <BaseSelect
                    name="color"
                    label="color"
                    options={colors}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={defaultColor}
                  />
                </div>
                <p>
                  Upload up to 4 pictures, each picture should be less than 5mb.
                </p>
                <ImageUploading
                  multiple
                  dataURLKey="data_url"
                  value={images}
                  maxNumber={4}
                  onChange={handleImageChange}
                >
                  {({ imageList, dragProps, onImageUpload, onImageRemove }) => (
                    <div className="reviews__drag">
                      {imageList.length > 0 && (
                        <ul className="reviews__upload">
                          {imageList.map((image: any, imageIndex: number) => (
                            <li key={`image__${Math.random()}`}>
                              <div
                                className="image"
                                style={{
                                  backgroundImage: `url(${image.data_url})`
                                }}
                              />
                              <span
                                className="reviews__close"
                                onClick={() => onImageRemove(imageIndex)}
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                      <span
                        className="reviews__add"
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <i className="icon icon-upload" />
                      </span>
                    </div>
                  )}
                </ImageUploading>
                <div className="reviews__actions" ref={ref}>
                  <Button variant="contained" type="submit" size="large">
                    Submit Review
                  </Button>
                  <Button size="large" onClick={handleCollapseClick}>
                    Cancel
                  </Button>
                </div>
              </form>
            </StyledReviewForm>
          </Collapse>
        </div>
        {reviews.length > 0 && (
          <StyledReviews>
            <div className="reviews__sort">
              <Select value={sort} onChange={handleSortChange}>
                <MenuItem value="most_recent">Most Recent</MenuItem>
                <MenuItem value="most_helpful">Most Helpful</MenuItem>
              </Select>
              <FormControlLabel
                control={
                  <Checkbox onChange={handleSortChecked} defaultChecked />
                }
                label="With photos"
              />
            </div>
            <StyleReviewList>
              {reviews.map((review: any) => {
                const Component: any = 'li'
                const reviewImages: any = review?.review_image ?? []
                return (
                  <Component
                    key={`review__${Math.random()}`}
                    itemScope=""
                    itemProp="review"
                    itemType="http://schema.org/Review"
                  >
                    <div className="rating">
                      <StyledRating
                        value={(review.average_rating * 5) / 100}
                        precision={0.1}
                        readOnly
                      />
                      <p>
                        <span>By:</span>&nbsp;
                        <span>{review.nickname}</span>
                      </p>
                      <p className="date">
                        <span>Date:</span>&nbsp;
                        <span>{formatDate(review.created_at)}</span>
                      </p>
                      {review?.color && (
                        <p className="date">
                          <span>Color:</span>&nbsp;
                          <span>{review.color}</span>
                        </p>
                      )}
                    </div>
                    <div className="details">
                      <div className="reviews__title">{review.summary}</div>
                      <div className="reviews__content">{review.text}</div>
                      {reviewImages.length > 0 && (
                        <div className="reviews__image">
                          {reviewImages.map((image: any) => {
                            return (
                              <Image
                                key={image.id}
                                src={image.url}
                                alt="Image"
                                onClick={() => {
                                  handleOpenLight(image.url)
                                }}
                              />
                            )
                          })}
                        </div>
                      )}
                      <ReviewHelp review={review} />
                    </div>
                  </Component>
                )
              })}
            </StyleReviewList>
            <StyledPagination>
              <Pagination
                count={totalPage}
                page={currentPage}
                onChange={(e: any, page: number) => setCurrentPage(page)}
              />
            </StyledPagination>
            <ImageLightbox
              images={thumbnailImages}
              index={index}
              isOpen={isOpen}
              onCloseRequest={onCloseRequest}
              onMovePrevRequest={onMovePrevRequest}
              onMoveNextRequest={onMoveNextRequest}
            />
          </StyledReviews>
        )}
      </div>
    </StyledReviewContainer>
  )
})

export default memo(CatalogReviews)
