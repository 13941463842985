import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { capitalize } from 'lodash'

import { GET_CMS_PAGE } from '@graphql/queries/getCmsPage'
import { ADD_CONTACT_US } from '@graphql/mutations/addContactUs'
import { ADD_DISCOUNT_INFO } from '@graphql/mutations/addDiscountInfo'
import { ADD_COOPERATION } from '@graphql/mutations/addCooperation'
import { ADD_WHOLESALE_PROGRAM } from '@graphql/mutations/addWholesaleProgram'
import { serialize } from '@headless/utils'

export const useCmsPage = (id: number) => {
  const { enqueueSnackbar } = useSnackbar()
  const { data } = useQuery(GET_CMS_PAGE, {
    variables: { id }
  })
  const [contactUsMutation] = useMutation(ADD_CONTACT_US)
  const [discountInfoMutation] = useMutation(ADD_DISCOUNT_INFO)
  const [cooperationMutation] = useMutation(ADD_COOPERATION)
  const [WholesaleMutation] = useMutation(ADD_WHOLESALE_PROGRAM)
  const storeConfig = useSelector((state: any) => state.app.storeConfig)
  const [visible, setVisible] = useState<boolean>(false)

  const websiteName = useMemo(() => {
    return capitalize(storeConfig?.website_name ?? '')
  }, [storeConfig])

  const cmsPage: any = data?.cmsPage ?? null

  useEffect(() => {
    setVisible(true)
  }, [])

  // Contact Us Page
  useEffect(() => {
    if (visible) {
      window
        .$('body')
        .off('click', '#contact-addQuestions-button')
        .on('click', '#contact-addQuestions-button', async () => {
          const form: any = window.$('#contact-form')
          form.validate()
          const isValid: boolean = form.valid()

          if (isValid) {
            try {
              await form.addClass('loading')
              const params: any = serialize(form.serializeArray())
              const response: any = await contactUsMutation({
                variables: {
                  input: {
                    name: params.name,
                    email: params.email,
                    content: params.comment
                  }
                }
              })
              const result: any = response.data.addContactUs
              await form.removeClass('loading')
              if (result.code === '200') {
                await form.append(
                  `<span class="success">Thanks for your message, we will reply to you in 48 hours.</span>`
                )
                await form?.[0].reset()
                await form.resetForm()
              } else {
                await enqueueSnackbar(result.content, {
                  variant: 'warning'
                })
                await form.find('.success').remove()
              }
            } catch (error) {
              await form.removeClass('loading')
            }
          }
        })
    }
  }, [contactUsMutation, enqueueSnackbar, visible])

  // Discount Page
  useEffect(() => {
    if (visible) {
      window
        .$('body')
        .off('click', '#discount-form .link_btn')
        .on('click', '#discount-form .link_btn', async () => {
          const form: any = window.$('#discount-form')
          form.validate()
          const isValid: boolean = form.valid()

          if (isValid) {
            try {
              await form.addClass('loading')
              const params: any = serialize(form.serializeArray())
              const response: any = await discountInfoMutation({
                variables: {
                  input: {
                    firstname: params.firstname,
                    email: params.email,
                    lastname: params.lastname,
                    type_id: params.type_id,
                    image: params.image
                  }
                }
              })
              const result: any = response.data.addDiscountInfo
              await form.removeClass('loading')
              if (result.code === '200') {
                await form.append(
                  `<p class="form_message">Your information was submitted successfully!</p>`
                )
                await form?.[0].reset()
                await form.find('.show_img').attr('src', '')
                await form.find("input[name='image']").val('')
                await form.find("input[name='image_file']").show()
                await form.find('.show_img').hide()
                await form.resetForm()
              } else {
                await enqueueSnackbar(result.content, {
                  variant: 'warning'
                })
                await form?.[0].reset()
                await form.find('.show_img').attr('src', '')
                await form.find("input[name='image']").val('')
                await form.find("input[name='image_file']").show()
                await form.find('.show_img').hide()
                await form.resetForm()
                await form.find('.form_message').remove()
              }
              await form.removeClass('loading')
            } catch (error) {
              await form.removeClass('loading')
            }
          }
        })
    }
  }, [discountInfoMutation, enqueueSnackbar, visible])

  // Collaboration Page
  useEffect(() => {
    if (visible) {
      window
        .$('body')
        .off('click', '#cooperation-form-button')
        .on('click', '#cooperation-form-button', async () => {
          const form: any = window.$('#cooperationform')
          form.validate()
          const isValid: boolean = form.valid()

          if (isValid) {
            try {
              await form.addClass('loading')
              const params: any = serialize(form.serializeArray())
              const response: any = await cooperationMutation({
                variables: {
                  input: {
                    city: params.city,
                    country: params.country,
                    email: params.email,
                    firstname: params.firstname,
                    insfanscount: params.insfanscount,
                    insname: params.insname,
                    lastname: params.lastname,
                    websiteurl: params.websiteurl
                  }
                }
              })
              const result: any = response.data.addCooperation
              await form.removeClass('loading')
              if (result.code === '200') {
                await form.append(
                  `<span class="success">Submitted successfully, we will check and reply to you in 72 hours.</span>`
                )
                await form?.[0].reset()
                await form.resetForm()
              } else {
                await enqueueSnackbar(result.content, {
                  variant: 'warning'
                })
                await form.find('.success').remove()
              }
            } catch (error) {
              await form.removeClass('loading')
            }
          }
        })
    }
  }, [cooperationMutation, enqueueSnackbar, visible])

  // Wholesale Program Page
  useEffect(() => {
    if (visible) {
      window
        .$('body')
        .off('click', '#contact-wholesaleprogram-button')
        .on('click', '#contact-wholesaleprogram-button', async () => {
          const form: any = window.$('#wholesale-form')
          form.validate({
            errorPlacement: (error: any, element: any) => {
              if (element.is(':radio') || element.is(':checkbox')) {
                error.appendTo(element.parent())
              } else {
                error.insertAfter(element)
              }
            }
          })
          const isValid: boolean = form.valid()

          if (isValid) {
            try {
              await form.addClass('loading')
              const params: any = serialize(form.serializeArray())
              const response: any = await WholesaleMutation({
                variables: {
                  input: {
                    businesstype: params.lastname,
                    email: params.email,
                    firstname: params.firstname,
                    howknow: params.howknow,
                    interested: params.interested,
                    lastname: params.lastname,
                    phone: params.phone,
                    storelocation: params.storelocation,
                    story: params.story,
                    website: params.website
                  }
                }
              })
              const result: any = response.data.addWholesaleProgram
              await form.removeClass('loading')
              if (result.code === '200') {
                await form.append(
                  `<span class="success">Submitted successfully, we will check and reply to you in 72 hours.</span>`
                )
                await form?.[0].reset()
                await form.resetForm()
              } else {
                await enqueueSnackbar(result.content, {
                  variant: 'warning'
                })
                await form.find('.success').remove()
              }
            } catch (error) {
              await form.removeClass('loading')
            }
          }
        })
    }
  }, [WholesaleMutation, enqueueSnackbar, visible])

  // Javascript Effect
  useEffect(() => {
    if (visible) {
      window
        .$('body')
        .off('click', '.viewmore')
        .on('click', '.viewmore', async (e: Event) => {
          const current: any = window.$(e.target)
          current.parents('.products').find('.defnone').toggleClass('n')
          current.toggleClass('less')

          if (current.text() === 'View More') {
            current.text('View Less')
          } else {
            current.text('View More')
          }
        })

      window
        .$('body')
        .off('click', '.quertions .title')
        .on('click', '.quertions .title', async (e: Event) => {
          const current: any = window.$(e.target)
          current.parent().toggleClass('active')
          current.siblings().toggle()
        })

      window
        .$('body')
        .off('click', '.influencerinfo button')
        .on('click', '.influencerinfo button', async (e: Event) => {
          const current: any = window.$(e.target)

          if (current.parents('.item').hasClass('active')) {
            current.parents('.item').removeClass('active')
            current.text('View All')
          } else {
            current.parents('.item').addClass('active')
            current.text('Fold')
          }
        })
    }
  }, [visible])

  return {
    cmsPage,
    visible,
    websiteName
  }
}
