import { gql, DocumentNode } from '@apollo/client'

export const ADD_WHOLESALE_PROGRAM: DocumentNode = gql`
  mutation addWholesaleProgram($input: WholesaleProgramInput) {
    addWholesaleProgram(input: $input) {
      code
      content
      message
    }
  }
`
