import styled from '@emotion/styled'

export const StyledCatalogMain = styled.div`
  margin-top: 4rem;

  .tab {
    &__item {
      border-bottom: 1px solid #eae7e4;
    }

    &__title {
      padding: 1rem 20px 1rem 0;
      margin-bottom: 0;
      color: #222;
      font-size: 1.125rem;
      cursor: pointer;
      text-transform: uppercase;
      position: relative;

      &:before {
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -8px;
        content: '';
        height: 16px;
        width: 2px;
        background-color: #1b1e22;
        transition: all 0.3s;
      }

      &:after {
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -1px;
        content: '';
        width: 16px;
        height: 2px;
        background-color: #1b1e22;
      }

      &.active {
        &:before {
          transform: rotate(90deg);
        }
      }
    }

    &__list {
      margin: 1rem 0;

      > li {
        margin-bottom: 5px;
        margin-left: 20px;
        font-size: 0.875rem;
        color: #222;
        list-style: disc;
      }
    }

    &__area {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
    border-top: 1px solid #eae7e4;

    .tab {
      &__area {
        font-size: 0.75rem;
      }
    }
  }
`

export const StyledScrillArea = styled.div`
  margin-bottom: 2rem;
  font-size: 0.875rem;

  .shippingreturn {
    color: #222;

    p {
      font-weight: 600;
    }

    table {
      font-size: 0.875rem;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      max-width: 100%;

      td,
      th {
        padding: 8px 10px;
        border: 1px #999 solid;
        vertical-align: inherit;
        text-align: center;
      }
    }

    ul {
      margin: 15px 0 20px 0;
      font-size: 0.875rem;

      li {
        list-style: disc;
        margin-left: 17px;
        margin-bottom: 5px;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .shippingreturn {
      p {
        font-size: 0.75rem;
      }

      table {
        td,
        th {
          font-size: 0.75rem;
        }
      }

      ul {
        font-size: 0.75rem;

        li {
          a {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`
