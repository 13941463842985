import { FC, memo } from 'react'
import { useSelector } from 'react-redux'

import { StyledShared } from './styled'

interface ProductProps {
  product: any
}

const CatalogShare: FC<ProductProps> = ({ product }) => {
  const { name, main_image, url_key } = product
  const storeConfig = useSelector((state: any) => state.app.storeConfig)
  const baseURL: string = `${process.env.PWA_URL}${url_key}${
    storeConfig?.product_url_suffix ?? ''
  }`

  return (
    <StyledShared className="product__social">
      <li>
        <span>Share With：</span>
      </li>
      <li>
        <a
          href={`https://www.facebook.com/sharer.php?u=${baseURL}`}
          className="icon icon-facebook"
          data-href={name}
          data-send="false"
          data-layout="button_count"
          data-width="60"
          data-show-faces="false"
          rel="nofollow noreferrer"
          target="_blank"
        />
      </li>
      <li>
        <a
          href={`https://pinterest.com/pin/create/button/?url=${baseURL}&amp;media=${main_image.url}&amp;description=${name}`}
          className="icon icon-pinterest"
          data-pin-do="buttonPin"
          data-pin-config="beside"
          data-pin-zero="true"
          rel="nofollow noreferrer"
          target="_blank"
        />
      </li>
      <li>
        <a
          href={`mailto:?subject=Look what I found on ${name};${baseURL}?utm_source=email?utm_medium=shares?utm_campaign=ugc`}
          className="icon icon-email"
          rel="nofollow"
        />
      </li>
    </StyledShared>
  )
}

export default memo(CatalogShare)
