import { gql, DocumentNode } from '@apollo/client'

export const ADD_CONTACT_US: DocumentNode = gql`
  mutation addContactUs($input: AddContactUsInput) {
    addContactUs(input: $input) {
      code
      content
      message
    }
  }
`
