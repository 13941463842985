import { gql, DocumentNode } from '@apollo/client'

export const ADD_COOPERATION: DocumentNode = gql`
  mutation addCooperation($input: addCooperationInput) {
    addCooperation(input: $input) {
      code
      content
      message
    }
  }
`
