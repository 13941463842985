import { useEffect } from 'react'

const useScript = (snippets: string[], attrs: any) => {
  useEffect(() => {
    if (!snippets || snippets.length === 0) {
      return
    }

    const scripts: HTMLScriptElement[] = []

    snippets.forEach((snippet) => {
      const script: any = document.createElement('script')
      script.async = true

      for (const key in attrs) {
        if (Object.prototype.hasOwnProperty.call(attrs, key)) {
          script[key] = attrs[key]
        }
      }

      script.innerHTML = decodeURIComponent(snippet.replace(/%/g, '%25'))

      document.body.appendChild(script)

      scripts.push(script)
    })

    return () => {
      scripts.forEach((script) => {
        document.body.removeChild(script)
      })
    }
  }, [snippets, attrs])
}

export default useScript
