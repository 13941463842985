import { FC, memo, useState } from 'react'
import { clsx } from 'clsx'
import parse, { HTMLReactParserOptions } from 'html-react-parser'

import { Collapse } from '@headless/ui'

import { StyledCatalogMain, StyledScrillArea } from './styled'

interface ProductProps {
  product: any
}

const CatalogCollapse: FC<ProductProps> = ({ product }) => {
  const { description, shipping_policy } = product
  const attributes: any[] = product?.show_attribute ?? []
  const [detailChecked, setDetailChecked] = useState<boolean>(true)
  const [shippingChecked, setShippingChecked] = useState<boolean>(false)

  const options: HTMLReactParserOptions = {
    replace: (node: any) => {
      if (node.type === 'script') {
        const script = document.createElement('script')

        if (node.attribs && node.attribs.src) {
          script.src = node.attribs.src
          script.async = true
        } else {
          script.innerHTML = node.children[0].data
        }

        document.head.appendChild(script)
      }
    }
  }

  const handleDetailClick = () => {
    setDetailChecked((prevState: any) => !prevState)
  }

  const handleShippingClick = () => {
    setShippingChecked((prevState: any) => !prevState)
  }

  return (
    <StyledCatalogMain>
      <div className="tab__item">
        <p
          className={clsx({
            tab__title: true,
            active: detailChecked
          })}
          aria-hidden="true"
          onClick={handleDetailClick}
        >
          <span>Product Details</span>
        </p>
        <Collapse in={detailChecked}>
          <div className="tab__area">
            {parse(description.html, options)}
            {attributes.length > 0 && (
              <ul className="tab__list">
                {attributes.map((attr: any) => {
                  return (
                    <li key={attr.code}>
                      <span>{attr.label}:</span>&nbsp;
                      <span>{attr.value}</span>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </Collapse>
      </div>
      <div className="tab__item">
        <p
          className={clsx({
            tab__title: true,
            active: shippingChecked
          })}
          aria-hidden="true"
          onClick={handleShippingClick}
        >
          <span>Shipping & Return</span>
        </p>
        <Collapse in={shippingChecked}>
          <StyledScrillArea>
            {parse(shipping_policy.content, options)}
          </StyledScrillArea>
        </Collapse>
      </div>
    </StyledCatalogMain>
  )
}

export default memo(CatalogCollapse)
